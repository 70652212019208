import './userDisplay.css';
import React, {Component} from "react";
import {UserDto} from "../model/functionModels";

interface UserDisplayProps {
    user: UserDto,
    loggedInUser?: UserDto,
    replaceYourName?: boolean,
    showName: boolean
    hideBackground?: boolean,
    replacementSuffix?: string,
    suffix?: string
    smallIcon?: boolean,
    abbreviateName?: boolean
}

class UserDisplay extends Component<UserDisplayProps, {}> {

    constructor(props: UserDisplayProps) {
        super(props, {})
        this.getUserIcon = this.getUserIcon.bind(this);
    }

    getUserIcon() {
        if (this.props.user.gravatarIcon) {
            return this.props.user.gravatarIcon;
        } else {
            return this.props.user.icon;
        }
    }
    render() {
        return <div className="UserDisplay-Wrapper">
            <div className={"UserDisplay-MainWrapper" +  (this.props.showName && !this.props.hideBackground ? " UserDisplay-MainWrapperBackgrounded" : "")}>
                <div className={"UserDisplay-ImageWrapper"+ (this.props.smallIcon? " UserDisplay-ImageSmall" : " UserDisplay-ImageLarge")}
                     style={{backgroundImage: "url(" + this.getUserIcon() + ")"}}>
                </div>
                {this.props.showName &&
                <div className="UserDisplay-NameWrapper">
                    {this.props.user && (this.props.user.id === this.props.loggedInUser?.id && this.props.replaceYourName) ? "You" : (this.props.abbreviateName? `${this.props.user.firstName} ${this.props.user.lastName.charAt(0)}` : `${this.props.user.firstName} ${this.props.user.lastName}`)}
                </div>
                }
            </div>
            {this.props.suffix &&
            <div className="UserDisplay-Suffix" style={{flex: 1}}>
                {(this.props.user.id === this.props.loggedInUser?.id && this.props.replaceYourName) ? this.props.replacementSuffix : this.props.suffix}
            </div>
            }
        </div>;
    }

}

export default UserDisplay;
