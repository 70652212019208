import React, {useEffect, useState} from 'react';
import './App.css';
import CookieConsent, {Cookies, getCookieConsentValue,} from "react-cookie-consent";
import firebase from "firebase/app";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./component/structural/header";
import Login from "./component/structural/login";
import ExpenseGroups from "./component/structural/expenseGroupsPage";
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export enum ToastErrorLevel {
    INFO, ERROR
}

function App() {

    const [loggedIn, setLoggedIn] = useState(false);

    const handleAcceptCookie = () => {
        firebase.analytics();
    };

    const handleDeclineCookie = () => {
        // remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid")
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    const addToastMessage = (body: string,
                             level: ToastErrorLevel): any => {
        switch (level) {
            case ToastErrorLevel.ERROR: {
                ToastsStore.error(body)
                break;
            }
            case ToastErrorLevel.INFO: {
                ToastsStore.info(body)
                break;
            }
        }
    }

    // @ts-ignore
    return (
        <div className="dark-theme App-wrapper">
            <div className="App">
                <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore}/>
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<div>
                            <Header key={"Header-" + loggedIn}/>
                            <ExpenseGroups toastGenerator={addToastMessage} onLogout={() => {
                                setLoggedIn(false);
                            }
                            }/></div>}/>
                        <Route path="/login" element={<div>
                            <Header key={"Header-" + loggedIn}/><Login toastGenerator={addToastMessage} onLogin={() => {
                            setLoggedIn(true);
                        }
                        }/></div>}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <script src="/__/firebase/8.4.1/firebase-app.js"/>
            <script src="/__/firebase/init.js"/>
        </div>

    );
}

export default App;
