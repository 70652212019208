import './transactionList.css';
import React, {Component} from "react";
import {ExpenseDto, PaymentDto, UserDto} from "../model/functionModels";
import UserDisplay from "./userDisplay";
import Pagination from "./pagination";

interface TransactionListState {
    page: number,
    transactionsGrouped: TransactionPage[],
}

interface TransactionPage {
    key: string,
    dayGroups: TransactionDay[],
}

interface TransactionDay {
    key: string,
    transactions: (ExpenseDto|PaymentDto)[],
}

interface TransactionListProps {
    title: string,
    transactions: (ExpenseDto|PaymentDto)[],
    involvedUsers: UserDto[],
    showDetails: boolean,
    displayMode: DisplayMode,
}

export enum DisplayMode {
    PAYMENT = "PAYMENT", EXPENSE = "EXPENSE"
}

export class TransactionList extends Component<TransactionListProps, TransactionListState> {

    constructor(props: TransactionListProps) {
        super(props, {})
        this.getDayOfMonthForTransaction = this.getDayOfMonthForTransaction.bind(this);
        this.getUserDtoForUser = this.getUserDtoForUser.bind(this);
        let splitTransactions = this.splitTransactions(props.transactions);
        this.state = {
            page: splitTransactions.length -1,
            transactionsGrouped: splitTransactions
        };
    }


    componentDidUpdate(prevProps: Readonly<TransactionListProps>, prevState: Readonly<TransactionListState>, snapshot?: any) {
        if (prevProps !== this.props) {
            let splitTransactions = this.splitTransactions(this.props.transactions);
            this.setState({
                page: splitTransactions.length -1,
                transactionsGrouped: splitTransactions
            });
        }
    }

    getUserDtoForUser(id: string) {
        const userDto = this.props.involvedUsers.filter((user) => user.id === id)[0];
        if (!userDto) {
            console.error("Failed finding user with id: ", id, "in", this.props.displayMode, this.props.involvedUsers);
        }
        return userDto;
    }

    splitTransactions(transactions: (ExpenseDto|PaymentDto)[]) {
        let result: TransactionPage[] = [];
        // we reverse all transactions to start with most recent first
        transactions.forEach((t : ExpenseDto | PaymentDto) => {
            const monthKey = this.getMonthKeyForTransaction(t);
            const dayKey = this.getDayKeyForTransaction(t);
            let foundIndex = result.findIndex((r) => r.key === monthKey);
            if (foundIndex >= 0) {
                let foundDayIndex = result[foundIndex].dayGroups.findIndex((d) => d.key === dayKey);
                if (foundDayIndex >= 0) {
                    result[foundIndex].dayGroups[foundDayIndex].transactions.push(t);
                } else {
                    result[foundIndex].dayGroups.push({
                        key: dayKey,
                        transactions: [t]
                    });
                }
            } else {
                result.push({
                    key: monthKey,
                    dayGroups: [{
                        key: dayKey,
                        transactions: [t]
                    }],
                })
            }
        })
        return result;
    }

    getMonthKeyForTransaction(transaction: ExpenseDto | PaymentDto) {
        // transaction.date
        const dateParts = transaction.date.split("-");

        const year = dateParts[0].slice(-2);
        const month = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1).toLocaleString('default', { month: 'long' });

        return `${month} ${year}`;
    }

    getDayKeyForTransaction(transaction: ExpenseDto | PaymentDto) {
        let numeric = this.getDayOfMonthForTransaction(transaction);
        let terminator = "th";
        if (numeric%10 === 1) {
            terminator = "st";
        } else if (numeric%10 === 2) {
            terminator = "nd";
        } else if (numeric%10 === 3) {
            terminator = "rd";
        }

        let prefix = transaction.date.split("-")[2];
        if (numeric<10) {
            prefix = prefix.slice(-1);
        }
        return prefix+terminator;
    }

    getDayOfMonthForTransaction(transaction: ExpenseDto | PaymentDto) {
        const dateParts = transaction.date.split("-");
        return parseInt(dateParts[2]);
    }

    async componentDidMount() {
    }


    renderTransactions() {
        const tGroup = this.state.transactionsGrouped[this.state.page];
        return <div className="TransactionList-Group">
            {tGroup && tGroup.dayGroups && tGroup.dayGroups
                .sort((t1, t2) => Math.min(1, Math.max(-1, this.getDayOfMonthForTransaction(t2.transactions[0]) - this.getDayOfMonthForTransaction(t1.transactions[0]))))
                .map(dayGroup => <div className="TransactionList-DayEntry">
                <div className="TransactionList-DayHeader">{dayGroup.key}</div>
                {dayGroup.transactions
                    .map((t : ExpenseDto | PaymentDto) =>
                    <div className="TransactionList-TransactionEntry">
                        <div className="TransactionList-Payer"><UserDisplay user={this.getUserDtoForUser(t.userWhoPaid)} showName={false}
                                                                            smallIcon={false}/></div>
                        <div className="TransactionList-TargetUsersAndDetails">
                            <div className="TransactionList-TargetUsers">
                                {this.props.displayMode === DisplayMode.EXPENSE ?
                                    (t as ExpenseDto).userPaidFor.map(user => <UserDisplay key={"user-" + user} user={this.getUserDtoForUser(user)}
                                                                            showName={false} smallIcon={true}/>)
                                    : <UserDisplay user={this.getUserDtoForUser((t as PaymentDto).userPaidTo)} showName={false} smallIcon={true}/>}
                            </div>
                            <div className="TransactionList-TransactionDetails">
                                {this.props.showDetails && this.props.displayMode === DisplayMode.EXPENSE &&
                                    <div className="TransactionList-TargetDetails">{(t as ExpenseDto).details}</div>}
                                <div
                                    className="TransactionList-TransactionAmount">
                                    <div>€&nbsp;{Math.floor(t.amount / 100)}.{t.amount % 100 > 9 ? "" : 0}{t.amount % 100}</div>
                                </div>
                            </div>
                        </div>

                    </div>)}
                </div>)}
        </div>
    }

    render() {
        return <div className="TransactionList-Wrapper">
            <div className="TransactionList-Header"><h1>{this.props.title}</h1></div>
            <div className="TransactionList-PaginationHeader">
                <Pagination page={this.state.page} pageHeaders={this.state.transactionsGrouped.map((t) => t.key)} showWhenSingle={true} onChange={(p: number) => {
                    this.setState({
                        page: p
                    })
                }}/>
            </div>
            {this.renderTransactions()}
        </div>
    }

}