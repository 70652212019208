import LocalStorageService from "./localStorageService";
import RestService, {ErrorDetails, ResponseStatus, RestCall} from "./restService";
import EnvironmentService from "./environmentService";
import {GetUsersResponseDto, UserDto} from "../model/functionModels";
import AuthenticationService from "./authenticationService";

async function getAllUsersList(onSuccess: (a: UserDto[]) => Promise<any>,
                               onFailure: (a: string) => Promise<any>,
                               onRedirection: (newUrl: string) => Promise<any>) {
    await getAllUsersInternal([], 0, 0, onSuccess, onFailure, onRedirection);
}

async function getAllUsersInternal(previousDetails: UserDto[],
                                   currentPage: number,
                                   pageSize: number,
                                   onSuccess: (a: UserDto[]) => Promise<any>,
                                   onFailure: (a: string) => Promise<any>,
                                   onRedirection: (newUrl: string) => Promise<any>) {
    await RestService.doAuthenticatedCall({
            url: EnvironmentService.getFunctionUrl() + "/users?skip=" + (currentPage * pageSize),
            method: "GET",
        } as RestCall,
        async (response: ResponseStatus) => {
            // GetUsersResponseDto
            const result: GetUsersResponseDto = response.body;
            const fullList: UserDto[] = previousDetails.concat(result.users);
            if (result.totalRecords > fullList.length) {
                // we have pending records to query
                await getAllUsersInternal(fullList, result.pageNumber +1 , result.pageSize, onSuccess, onFailure, onRedirection);
            } else {
                await onSuccess(fullList);
            }
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed getting users!`, error);
            await onFailure(error.error);
        }, onRedirection)
}


async function getOwnUserDetails(onSuccess: (a: UserDto) => Promise<any>,
                                 onFailure: (a: string) => Promise<any>,
                                 onRedirection: (newUrl: string) => Promise<any>) {

    let id = LocalStorageService.getUserDetails()?.loggedInUser?.id;
    if (!id) {
        await AuthenticationService.verifyLoggedIn(async userDetails => {
                await getUserDetailsById(userDetails.loggedInUser.id, onSuccess, onFailure, onRedirection);
            },
            async failure => {
                await onFailure(failure);
            }, onRedirection);
    } else {
        await getUserDetailsById(id, onSuccess, onFailure, onRedirection);
    }
}

async function getUserDetailsById(id: string,
                                  onSuccess: (a: UserDto) => Promise<any>,
                                  onFailure: (a: string) => Promise<any>,
                                  onRedirection: (newUrl: string) => Promise<any>) {
    await RestService.doAuthenticatedCall({
            url: EnvironmentService.getFunctionUrl() + "/users/" + id,
            method: "GET",
        } as RestCall,
        async (response: ResponseStatus) => {
            await onSuccess(response.body);
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed getting user details!`, error);
            await onFailure(error.error);
        },
        onRedirection)
}


const UserDetailsService = {
    getAllUsersList,
    getUserDetailsById,
    getOwnUserDetails,
}

export default UserDetailsService;