import './addTransaction.css';
import React, {Component} from "react";
import {
    PostPaymentRequestDto,
    PostExpenseRequestDto, UserDto
} from "../model/functionModels";
import TransactionService from "../services/transactionService";
import {Multiselect} from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import CurrencyInput from "react-currency-input-field";
import Switcher from "./switcher";
import Button from "./button";

interface AddTransactionState {
    typeChecked: boolean
    userWhoPaid: UserRepresentation[],
    userPaidFor: UserRepresentation[],
    userPaidTo: UserRepresentation[],
    date: Date,
    details: string,
    amount: string,
    counter: number
}

interface AddTransactionProps {
    expenseGroupId: string,
    loggedInUser: UserDto,
    possibleUsers: UserDto[],
    onFailure: (a: string) => Promise<any>,
    onRedirection: (newUrl: string) => Promise<any>,
    refreshPageContent: any,
}

interface UserRepresentation {
    name: string,
    id: string
}

class AddTransaction extends Component<AddTransactionProps, AddTransactionState> {

    constructor(props: AddTransactionProps) {
        super(props, {})
        this.handleAddTransaction = this.handleAddTransaction.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.renderReadyContent = this.renderReadyContent.bind(this);
        this.state = {
            typeChecked: true,
            date: new Date(),
            userWhoPaid: [{name: `${this.props.loggedInUser.firstName} ${this.props.loggedInUser.lastName}`, id: this.props.loggedInUser.id} as UserRepresentation],
            userPaidFor: [],
            userPaidTo: [],
            details: '',
            amount: '',
            counter: 1,
        };
    }

    async handleAddTransaction() {
        const date = this.formatDate(this.state.date!);
        const amount = Math.floor(parseFloat(this.state.amount!) * 100);
        if (this.state.typeChecked) {
            await TransactionService.createExpense(this.props.expenseGroupId,
                {
                    userWhoPaid: this.state.userWhoPaid![0].id,
                    userPaidFor: this.state.userPaidFor!.map(u => u.id),
                    date: date,
                    details: this.state.details!,
                    amount: amount,
                } as PostExpenseRequestDto,
                async () => {
                    // we clear the transaction form
                    this.setState({
                        typeChecked: true,
                        date: new Date(),
                        userWhoPaid: [],
                        userPaidFor: [],
                        userPaidTo: [],
                        details: '',
                        amount: '',
                        counter: this.state.counter+1,
                    });
                    // we update the transactions list and the debt map
                    await this.props.refreshPageContent();
                },
                this.props.onFailure,
                this.props.onRedirection);
        } else {
            await TransactionService.createPayment(this.props.expenseGroupId,
                {
                    userWhoPaid: this.state.userWhoPaid![0].id,
                    userPaidTo: this.state.userPaidTo[0].id,
                    date: date,
                    amount: amount,
                } as PostPaymentRequestDto,
                async () => {
                    // we clear the transaction form
                    this.setState({
                        typeChecked: true,
                        date: new Date(),
                        userWhoPaid: [],
                        userPaidFor: [],
                        userPaidTo: [],
                        details: '',
                        amount: '',
                        counter: this.state.counter+1,
                    });
                    // we update the transactions list and the debt map
                    await this.props.refreshPageContent();
                },
                this.props.onFailure,
                this.props.onRedirection);
        }

    }

    formatDate(date: Date) : string {
        return `${date.getFullYear()}-${(date.getMonth() + 1) > 9 ? "" : "0"}${date.getMonth() + 1}-${date.getDate() > 9 ? "" : "0"}${date.getDate()}`;
    }


    renderReadyContent() {
        return <div className="AddTransaction-Wrapper">
            <div className="AddTransaction-Switcher" key="addTransactionSwitch">
                <Switcher
                    enabled={true}
                    value={this.state.typeChecked}
                    onText="Add Expense"
                    offText="Add Payment"
                    onChange={(checked: boolean) => {
                        this.setState({
                            typeChecked: checked,
                        })
                    }}
                />
            </div>
            <div className="AddTransaction-UserWhoPaid" key="addTransactionPayingUser">
                <Multiselect
                    key={"Multiselect-whoPaid-"+this.state.counter}
                    placeholder="User Who Paid"
                    options={this.props.possibleUsers.map(u => {
                        return {name: `${u.firstName} ${u.lastName}`, id: u.id}
                    })}
                    selectedValues={this.state.userWhoPaid} // Preselected value to persist in dropdown
                    onSelect={(event) => {
                        this.setState({
                            userWhoPaid: event
                        })
                    }
                    }
                    onRemove={(event) => {
                        this.setState({
                            userWhoPaid: event
                        })
                    }
                    }
                    singleSelect={true}
                    displayValue="name" // Property name to display in the dropdown options
                />
            </div>
            <div key="addTransactionUsers" className="AddTransaction-UserWhoIsPaidFor">
                {this.state.typeChecked
                    ?
                    <Multiselect
                        key={"Multiselect-expenseFor-"+this.state.counter}
                        placeholder="Who this expense is for"
                        options={this.props.possibleUsers.map(u => {
                            return {name: `${u.firstName} ${u.lastName}`, id: u.id} as UserRepresentation
                        })}
                        selectedValues={this.state.userPaidFor}
                        onSelect={(event) => {
                            this.setState({
                                userPaidFor: event
                            })
                        }
                        }
                        onRemove={(event) => {
                            this.setState({
                                userPaidFor: event
                            })
                        }
                        }
                        displayValue="name"
                    />

                    :
                    <Multiselect
                        key={"Multiselect-whoWasPaid-"+this.state.counter}
                        placeholder="Who was paid"
                        options={this.props.possibleUsers.map(u => {
                            return {name: `${u.firstName} ${u.lastName}`, id: u.id} as UserRepresentation
                        })}
                        selectedValues={this.state.userPaidTo}
                        onSelect={(event) => {
                            this.setState({
                                userPaidTo: event
                            })
                        }
                        }
                        onRemove={(event) => {
                            this.setState({
                                userPaidTo: event
                            })
                        }
                        }
                        singleSelect={true}
                        displayValue="name"
                    />
                }

            </div>
            <div className="AddTransaction-Date" key="addTransactionDate">
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={this.state.date}
                    monthsShown={2}
                    onChange={(date: Date) => {
                        this.setState({
                            date: date
                        });
                    }
                    }
                    placeholderText="Date of Transaction"
                    maxDate={new Date()}

                />
            </div>
            {this.state.typeChecked &&
                <div  className="AddTransaction-Details">
            <input key="addTransactionDetails"
                   type="text" placeholder="Expense Details" value={this.state.details}
                   onChange={(event) => {
                       this.setState({
                           details: event.target.value
                       });
                   }
                   }/>
                </div>
            }
            <div className="AddTransaction-Amount"  key="addTransactionMoney"><CurrencyInput
                id="moneyInput"
                name="moneyInput"
                placeholder="Transaction Amount"
                value={this.state.amount}
                decimalsLimit={2}
                intlConfig={{
                    currency: "EUR",
                    locale: "en-MT",
                }}
                onValueChange={(value, name) => {
                    this.setState({
                        amount: value!
                    });
                }
                }
            /></div>
            <Button value={"Add Transaction"} onClick={this.handleAddTransaction} enabled={true}/>


        </div>;
    }

    render() {
        return this.props.possibleUsers ?
            this.renderReadyContent()
            : <div className="AddTransaction-Wrapper-Empty"/>;
    }

}

export default AddTransaction;
