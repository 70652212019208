import './button.css';
import React, {Component} from "react";

interface ButtonProps {
    value: string,
    onClick: any,
    enabled: boolean
    width? : string
    dangerButton?: boolean
}

class Button extends Component<ButtonProps, {}> {

    constructor(props: ButtonProps) {
        super(props, {})
    }

    render() {
        return <div className={"Button-Wrapper" + (this.props.enabled ? (this.props.dangerButton ?" Button-Scheme-Danger" :  " Button-Scheme-Main" ) : " Button-Disabled")}>
            <button
            style={{
                width: this.props.width ? this.props.width : "100%"
            }}
                onClick={(e) => {
                if (this.props.enabled) {
                    this.props.onClick(e);
                }
            }}>{this.props.value}</button>
        </div>;
    }

}

export default Button;
