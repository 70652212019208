import './pagination.css';
import React, {Component} from "react";
import {isMobile} from 'react-device-detect';

const maxPageVisibility = 5;
const maxOnEitherSide = (maxPageVisibility -1)/2;
interface PaginationProps {
    page: number,
    pageHeaders: string[],
    onChange: any,
    showWhenSingle?: boolean,
}
class Pagination extends Component<PaginationProps, { }> {

    constructor(props: PaginationProps) {
        super(props, {})
    }

    renderPagination() {
        let result = [];
        let currentDisplayPage = this.props.page + 1;
        if (this.props.page !== 0) {
            result.push(this.getButtonContent("pagination-start", 0, false, "«"));
        }
        if (this.props.pageHeaders.length! > maxPageVisibility) {
            // we show only the surrounding numbers
            let startingIndex = -maxOnEitherSide;
            let finishingIndex = maxOnEitherSide+1;
            if (currentDisplayPage === this.props.pageHeaders.length) {
                startingIndex = -(maxPageVisibility-1);
                finishingIndex = 1;
            } else if (currentDisplayPage === 1) {
                startingIndex = 0;
                finishingIndex = maxPageVisibility;
            } else if (currentDisplayPage > (this.props.pageHeaders.length! -2)) {
                // this means we are closer than 2 steps from the end. So we should start more steps to the left
                startingIndex -= -maxOnEitherSide - (currentDisplayPage  - this.props.pageHeaders.length! -maxOnEitherSide );
                finishingIndex = 1 + this.props.pageHeaders.length! - currentDisplayPage;
            } else if (this.props.page < maxOnEitherSide) {
                // this means we are closer than 2 steps from the start. So we should finish more to the right
                finishingIndex += maxOnEitherSide+1 - (currentDisplayPage);
                startingIndex = -this.props.page;
            }
            for (let i = startingIndex; i < finishingIndex && ((i+this.props.page) < this.props.pageHeaders.length!); i ++) {
                result.push(this.getButtonContent("pagination-"+i, i + this.props.page,  i === 0));
            }
        } else {
            for (let i = 0; i < this.props.pageHeaders.length!; i ++) {
                result.push(this.getButtonContent("pagination-"+i, i,  this.props.page === i));
            }
        }
        if (this.props.page !== (this.props.pageHeaders.length-1)) {
            result.push(this.getButtonContent("pagination-end", this.props.pageHeaders.length! -1, false,  "»"));
        }
        return result;
    }


    getButtonContent(key: string, onChangeValue: number, disabled: boolean, textOverride?: string) {
        return <div key={key} className={"Pagination-Button" + (disabled ? " Pagination-Button-Disabled" : "")}>
            <button disabled={disabled} onClick={() => this.props.onChange(onChangeValue)}>{textOverride ? textOverride : this.props.pageHeaders[onChangeValue]}</button>
        </div>;
    }


    renderAll() {
        return this.props.pageHeaders.map((header, index) => this.getButtonContent("pagination-"+index, index, index===this.props.page))
    }


    render() {
        if (!this.props.pageHeaders || (this.props.pageHeaders.length! <= 1 && !this.props.showWhenSingle) || (this.props.pageHeaders.length <= 0 && this.props.showWhenSingle)) {
            return <div/>
        }
        return <div className="Pagination-Wrapper">
                {isMobile? this.renderAll() : this.renderPagination()}
            </div>;
    }

}

export default Pagination;
