import './header.css';
import React, {Component} from "react";
import {UserDto} from "../model/functionModels";
import UserDetailsService from "../services/userDetailsService";
import UserDisplay from "./userDisplay";
import Button from "./button";
import LocalStorageService from "../services/localStorageService";
import {withRouter} from "./withRouter";
import RedirectionService from "../services/redirectionService";

interface HeaderState {
    user?: UserDto
}

class Header extends Component<{}, HeaderState> {

    constructor(props: {}) {
        super(props, {})
        this.state = {};
    }

    componentDidMount() {
        UserDetailsService.getOwnUserDetails(async newUser => {
                this.setState({
                    user: newUser
                })
            },
            async failure => {
                // we do nothing on failure cause we are present even when logged in
                this.setState({
                    user: undefined
                });
            },
            async redirection => {
                // we do nothing on failure cause we are present even when logged in
                this.setState({
                    user: undefined
                });
            })
    }


    render() {
        return (
            <div className="Header-Wrapper">
                <div className="Header-Title">Expense Tracker</div>
                {this.state.user && <div className="Header-Signout"><Button value="Sign Out" onClick={() => {
                    LocalStorageService.signOut();
                    // @ts-ignore
                    this.props.navigate(RedirectionService.redirectToLogin());
                }} width="5vw" enabled={true}/></div>}
                {this.state.user && <div className="Header-User"><UserDisplay user={this.state.user} showName={true}/></div>}
            </div>);
    }
}

export default withRouter(Header);