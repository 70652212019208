import './switcher.css';
import React, {Component} from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

interface SwitcherProps {
    value: boolean,
    onChange: any,
    onText: string,
    offText: string,
    enabled: boolean
}

class Switcher extends Component<SwitcherProps, {}> {

    constructor(props: SwitcherProps) {
        super(props, {})
        this.onChange = this.onChange.bind(this);
        this.state = {
        };
    }

    onChange(checked: boolean) {
        this.props.onChange(checked);
    }


    render() {
        return <div className={"Switcher-Wrapper" + (this.props.enabled ? " Switcher-Wrapper-Enabled" : "")} key="switcher">
            <BootstrapSwitchButton
                disabled={!this.props.enabled}
                checked={this.props.value}
                onlabel={this.props.onText}
                onstyle='primary'
                offlabel={this.props.offText}
                offstyle='secondary'
                style='w-100 mx-3'
                onChange={this.onChange}
            />
        </div>;
    }

}

export default Switcher;
