import './debtMap.css';
import React, {Component} from "react";
import {DebtMap as DebtMapDto, DebtMapEntry, UserDto} from "../model/functionModels";
import UserDisplay from "./userDisplay";

interface DebtMapProps {
    title: string
    debts: DebtMapDto
    userList: UserDto[],
    loggedInUser: UserDto,
}

class DebtMap extends Component<DebtMapProps, {}> {

    constructor(props: DebtMapProps) {
        super(props, {})
        this.transformUser = this.transformUser.bind(this);
        this.sortUserList = this.sortUserList.bind(this);
    }

    async componentDidMount() {
    }

    transformUser(id: string, suffix?: string, replacementSuffix?: string) {
        if (!id || !this.props.userList) {
            return id;
        }
        let userDto = this.props.userList.filter(u => u.id === id)[0];
        return <UserDisplay hideBackground={true} user={userDto} loggedInUser={this.props.loggedInUser!} replacementSuffix={replacementSuffix} replaceYourName={true} suffix={suffix} showName={true}/>
    }


    renderDebtEntry(personWhoIsOwed: string, val: number) {
        const amt = this.parseAmount(val);
        return <div className="DebtMapList-CollectionEntry">
            <div className="DebtMapList-CollectionEntryUser">{this.transformUser(personWhoIsOwed, amt, amt)}</div>
        </div>
    }

    parseAmount(val: number) {
        return `€ ${Math.floor(val / 100)}.${val % 100 > 9 ? "" : "0"}${val % 100}`;
    }

    renderDebtCollection(personWhoOwes: string, debtMap: DebtMapEntry) {
        return <div className="DebtMapList-Collection">
            <div className="DebtMapList-CollectionHeader">
                {this.transformUser(personWhoOwes, " owes", " owe")}
            </div>
            <div className="DebtMapList-CollectionEntries">
                {Object.keys(debtMap)
                    .sort(this.sortUserList)
                    .map(a => this.renderDebtEntry(a, debtMap[a]))
                }
            </div>
        </div>
    }

    sortUserList(a : string, b: string) {
        return a === this.props.loggedInUser?.id ? -1 : (b === this.props.loggedInUser?.id ? 1 : a.localeCompare(b));
    }

    render() {
        return <div className="DebtMapList-Wrapper">
            { this.props.debts && this.props.loggedInUser && Object.keys(this.props.debts)
                .sort(this.sortUserList)
                .map(a => this.renderDebtCollection(a, this.props.debts[a]))
            }
        </div>
    }

}

export default DebtMap;
