import './login.css';
import React, {Component} from "react";
import AuthenticationService from "../services/authenticationService";
import RedirectionService from "../services/redirectionService";
import {Navigate} from "react-router-dom";
import {ToastErrorLevel} from "../../App";

interface LoginState {
    email: string,
    password: string,
    navigation?: string,
}

interface LoginProps {
    toastGenerator: (body: string, level: ToastErrorLevel) => any,
    onLogin: any,
}

export default class Login extends Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props, {})
        this.state = {email: "", password: ""};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
        this.handlePassUpdate = this.handlePassUpdate.bind(this);
    }

    async componentDidMount() {
        // if already logged in, redirect to home page
        await AuthenticationService.verifyLoggedIn(async loggedInUser => {
                // if we are already logged in, we should redirect to the home page in this case
                console.log("Already logged in. Skipping login");
                this.setState({
                    navigation: RedirectionService.redirectToHome(0, true),
                });
            }, async failure => {
                this.props.toastGenerator(failure, ToastErrorLevel.ERROR);
            },
            async redirect => {
                // we do not actually redirect on failure in this case as we are already on the login page
            })
    }

    async handleSubmit(event: any) {
        // Prevent page reload
        event.preventDefault();
        await AuthenticationService.attemptLogin(this.state.email, this.state.password,
            async () => {
                await this.props.onLogin();
                this.setState({
                    navigation: RedirectionService.redirectToHome(0, true)
                })
            },
            async (error) => this.props.toastGenerator(error, ToastErrorLevel.ERROR))
    }

    handleEmailUpdate(event: any) {
        this.setState({
            ...this.state,
            email: event.target.value
        })
    }

    handlePassUpdate(event: any) {
        this.setState({
            ...this.state,
            password: event.target.value
        })
    }

    render() {
        return (
            <div>
                {this.state.navigation && <Navigate to={this.state.navigation}/>}
                <div className="Login-Wrapper">
                    <div className="Login-form">
                        <form>
                            <div className="Login-input-container">
                                <input type="text" name="email" required placeholder="Email"
                                       onChange={this.handleEmailUpdate} value={this.state.email}/>
                            </div>
                            <div className="Login-input-container">
                                <input type="password" name="pass" required placeholder="Password"
                                       onChange={this.handlePassUpdate} value={this.state.password}/>
                            </div>
                            <div className="Login-button-container">
                                <button onClick={this.handleSubmit}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>);
    }
}