import AuthenticationService from "./authenticationService";
export interface RestCall {
    url: string,
    method: string,
    body?: any,
    headers: any,
}

export interface ErrorDetails {
    error: string,
    response?: ResponseStatus
}

export interface ResponseStatus {
    ok: boolean
    statusCode: number,
    body: any
}

async function doUnauthenticatedCall(restCall: RestCall,
                                     onSuccess: (a: ResponseStatus) => Promise<any>,
                                     onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    try {
        fetch(restCall.url,
            {
                method: restCall.method,
                body: restCall.body ? JSON.stringify(restCall.body) : undefined,
                headers: buildBasicHeadersObject(restCall)
            })
            .then(parseResponseBody)
            .then(r => handleResponse(r, restCall, onSuccess, onFailure))
            .catch(e => handleFailure(e, restCall, onFailure));
    } catch (e) {
        await handleFailure(e, restCall, onFailure);
    }
}

async function handleFailure(e: any,
                             restCall: RestCall,
                             onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    console.log(e);
    await onFailure(restCall, {
        error: (typeof e === "string") ? e : e.message,

    })
}

async function doAuthenticatedCall(restCall: RestCall,
                                   onSuccess: (a: ResponseStatus) => Promise<any>,
                                   onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>,
                                   onRedirection: (newUrl: string) => Promise<any>) {

    await authenticatedCallWithRetryOn401(restCall, onSuccess, onFailure, onRedirection, 3);
}

async function authenticatedCallWithRetryOn401(restCall: RestCall,
                                               onSuccess: (a: ResponseStatus) => Promise<any>,
                                               onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>,
                                               onRedirection: (newUrl: string) => Promise<any>,
                                               remainingAttempts: number,
                                               firstError?: ErrorDetails) {
    if (remainingAttempts <= 0) {
        // we failed all attempts.
        await onFailure(restCall, firstError!);
    } else {
        await doAuthenticatedCallInternal(restCall, onSuccess,
            async (r, e) => {
                if (e.response && e.response.statusCode === 401) {
                    console.log("401 response. Retrying");
                    // we failed auth. so we retry the whole process
                    await authenticatedCallWithRetryOn401(restCall, onSuccess, onFailure, onRedirection,
                        remainingAttempts - 1,
                        firstError ? firstError : e);
                } else {
                    // we failed in another manner so we just exit instantly
                    await onFailure(r, e);
                }
            }, onRedirection);
    }
}

async function doAuthenticatedCallInternal(restCall: RestCall,
                                           onSuccess: (a: ResponseStatus) => Promise<any>,
                                           onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>,
                                           onRedirection: (newUrl: string) => Promise<any>) {
    await AuthenticationService.verifyLoggedIn(
        async userDetails => {
            try {
                fetch(restCall.url,
                    {
                        method: restCall.method,
                        body: restCall.body ? JSON.stringify(restCall.body) : undefined,
                        headers: buildBasicHeadersObject(restCall, userDetails.accessToken)
                    })
                    .then(parseResponseBody)
                    .then(r => handleResponse(r, restCall, onSuccess, onFailure))
                    .catch(e => handleFailure(e, restCall, onFailure));
            } catch (e) {
                await handleFailure(e, restCall, onFailure);
            }
        },
        async failure => {
            await onFailure(restCall, {
                error: failure
            });
        },
        onRedirection);

}

async function parseResponseBody(response: any): Promise<ResponseStatus> {
    const body = response.ok ? response.status === 204 ? undefined : await response.json() : await response.text()
    return {
        ok: response.ok,
        body: body,
        statusCode: response.status,
    };
}

async function handleResponse(response: ResponseStatus, restCall: RestCall,
                              onSuccess: (a: ResponseStatus) => Promise<any>,
                              onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    if (response.ok) {
        await onSuccess(response);
    } else {
        await onFailure(restCall, {
            response: response,
            error: response.body
        });
    }
}

function buildBasicHeadersObject(restCall: RestCall, jwtToken?: string): { [key: string]: string } {
    let headers: { [key: string]: string } = {};
    if (restCall.headers) {
        headers = {...restCall.headers};
    }
    if (restCall.body) {
        headers["Content-Type"] = "application/json";
    }
    if (jwtToken) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
    }
    return headers;
}

const RestService = {
    doAuthenticatedCall,
    doUnauthenticatedCall
}
export default RestService;