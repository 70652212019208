function redirectToLogin() : string{
    return "/login";
}

function redirectToHome(pageNumber: number,excludeClosed: boolean) : string{
    return `/?page=${pageNumber}&excludeClosed=${excludeClosed}`;
}


const RedirectionService = {
    redirectToLogin,
    redirectToHome,
}

export default RedirectionService;