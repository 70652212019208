export interface RefreshTokenResponseDto {
    token: string,
    user: UserDto,
}

export interface UserDto {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    active: boolean,
    icon: string,
    gravatarIcon: string,
}

export interface LoginResponseDto {
    token: string,
    refreshToken: string;
    user: UserDto;
}

interface PaginationDetails {
    pageNumber: number,
    pageSize: number,
    totalRecords: number
}

export interface GetUsersResponseDto extends PaginationDetails{
    users: UserDto[],
}


export interface ExpenseGroupDto {
    id: string,
    name: string,
    involvedUsers: UserDto[],
    state: ExpenseGroupStateDto,
    canBeClosed: boolean,
    expenses: ExpenseDto[],
    payments: PaymentDto[],
}

export enum ExpenseGroupStateDto {
    OPEN = "OPEN", CLOSED = "CLOSED"
}

export interface GetExpenseGroupResponseDto {
    expenseGroups: ExpenseGroupDetailedDto[],
}

export interface ExpenseGroupDetailedDto extends ExpenseGroupDto {
    /** user ID who owes : userID who is owed: debt **/
    rawDebts: DebtMap,
    simplifiedDebts: DebtMap,
}

export interface DebtMap {
    [id: string] : DebtMapEntry
}

export interface DebtMapEntry {
    [id: string] : number
}


export interface PostExpenseGroupRequestDto {
    name: string,
    involvedUserIds: string[]
}


interface BaseTransactionDto<T> {
    userWhoPaid: T,
    date: string,
    amount: number
}

export interface PaymentDto extends BaseTransactionDto<string> {
    id: string,
    userPaidTo: string,
}
export interface ExpenseDto extends BaseTransactionDto<string> {
    id: string,
    userPaidFor: string[],
    details: string,
}


export interface PostPaymentRequestDto extends BaseTransactionDto<string> {
    userPaidTo: string,
}

export interface PostExpenseRequestDto extends BaseTransactionDto<string> {
    userPaidFor: string[],
    details: string,
}
