import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyCYmSmeqS9gUvTh7SUp5Rpj0h31-_12Nkw",
    authDomain: "expense-calculator-382222.firebaseapp.com",
    projectId: "expense-calculator-382222",
    storageBucket: "expense-calculator-382222.appspot.com",
    messagingSenderId: "319332357211",
    appId: "1:319332357211:web:531ee48bf3bc5762cbdb7e",
    measurementId: "G-7TQB95Y4EF"
};

firebase.initializeApp(firebaseConfig);


ReactDOM.render(
    <div className="bodyWrapper">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


