import './expenseDetails.css';
import React, {Component} from "react";
import {
    ExpenseGroupDetailedDto,
    UserDto
} from "../model/functionModels";
import {TransactionList, DisplayMode} from "./transactionList";
import DebtMap from "./debtMap";
import AddTransaction from "./addTransaction";
import {isMobile} from 'react-device-detect';
import SwipablePanels from "./swipablePanels";

interface ExpenseDetailsState {
}

interface ExpenseDetailsProps {
    expenseGroup: ExpenseGroupDetailedDto,
    loggedInUser: UserDto,
    possibleUsers: UserDto[],
    onFailure: (a: string) => Promise<any>,
    onRedirection: (newUrl: string) => Promise<any>,
    refreshPageContent: any,
}

class ExpenseDetails extends Component<ExpenseDetailsProps, ExpenseDetailsState> {

    constructor(props: ExpenseDetailsProps) {
        super(props, {})
        this.state = {};
    }
    renderSimplifiedDebts() {
        if (!this.props.expenseGroup) {
            return <div/>
        }
        return <div className={"ExpandedDetails-DebtMap" + (isMobile ? "ExpandedDetails-MobileDebt" : " ExpandedDetails-FullScreenDebt")}><div className="ExpandedDetails-DebtMap-Header"><h1>Simplified Debts</h1></div><DebtMap
            loggedInUser={this.props.loggedInUser}
            key={"simplifieddebt-" + this.props.expenseGroup.id + "-" + (Object.keys(this.props.expenseGroup.simplifiedDebts!).length > 0)}
            title="Simplified Debts" debts={this.props.expenseGroup.simplifiedDebts!}
            userList={this.props.possibleUsers}/></div>;
    }

    renderRawDebts() {
        if (!this.props.expenseGroup) {
            return <div/>
        }
        return <div className={"ExpandedDetails-DebtMap" + (isMobile ? "ExpandedDetails-MobileDebt" : " ExpandedDetails-FullScreenDebt")}><div className="ExpandedDetails-DebtMap-Header"><h1>Unmodified Debts</h1></div>
            <DebtMap
            loggedInUser={this.props.loggedInUser}
            key={"rawdebt-" + this.props.expenseGroup.id + "-" + (Object.keys(this.props.expenseGroup!.rawDebts!).length > 0)}
            title="Unmodified Debts" debts={this.props.expenseGroup.rawDebts!}
            userList={this.props.possibleUsers}/></div>;
    }

    renderExpensesTable() {
        if (!this.props.expenseGroup.expenses) {
            return <div/>
        }
        return <div key={"EpensesList"} className={"ExpandedDetails-Expenses" + (isMobile ? "ExpandedDetails-MobileTransactions" : " ExpandedDetails-FullScreenTransactions")}><TransactionList title="Expenses"
                                                                          transactions={this.props.expenseGroup.expenses}
                                                                          involvedUsers={this.props.expenseGroup?.involvedUsers!}
                                                                          displayMode={DisplayMode.EXPENSE}
                                                                          showDetails={true}/>
        </div>;
    }

    renderPaymentsTable() {
        if (!this.props.expenseGroup.payments) {
            return <div/>
        }
        return <div key={"PaymentList"} className={"ExpandedDetails-Payments" + (isMobile ? "ExpandedDetails-MobileTransactions" : " ExpandedDetails-FullScreenTransactions")}><TransactionList title="Payments"
                                                                          transactions={this.props.expenseGroup.payments}
                                                                          involvedUsers={this.props.expenseGroup?.involvedUsers!}
                                                                          displayMode={DisplayMode.PAYMENT}
                                                                          showDetails={false}/>
        </div>;
    }

    renderAddTransactionsPanel() {
        return <AddTransaction expenseGroupId={this.props.expenseGroup.id}
                               loggedInUser={this.props.loggedInUser}
                               possibleUsers={this.props.expenseGroup?.involvedUsers!} onFailure={this.props.onFailure}
                               onRedirection={this.props.onRedirection} refreshPageContent={() => {
            this.props.refreshPageContent()
        }}/>;
    }

    render() {
        if (isMobile) {
            return  <div className="ExpandedDetails-Wrapper">
                <SwipablePanels panels={[this.renderSimplifiedDebts(),
                                       this.renderRawDebts(),
                                       this.renderExpensesTable(),
                                       this.renderPaymentsTable(),
                                       this.renderAddTransactionsPanel()]}/>
            </div>
        } else {
            return (
                <div className="ExpandedDetails-Wrapper">
                    <div className="ExpandedDetails-DebtMapContainer">
                        {this.props.expenseGroup && this.renderSimplifiedDebts()}
                        {this.props.expenseGroup && this.renderRawDebts()}
                    </div>
                    <div className="ExpandedDetails-TransactionContainer">
                        {this.props.expenseGroup.expenses && this.renderExpensesTable()}
                        {this.props.expenseGroup.payments && this.renderPaymentsTable()}
                    </div>
                    <div className="ExpandedDetails-TransactionAdditionContainer">
                        {this.renderAddTransactionsPanel()}
                    </div>
                </div>)
        }
    }

}

export default ExpenseDetails;
