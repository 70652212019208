import LocalStorageService, {UserDetails, UserDetailsStore} from "./localStorageService";
import RedirectionService from "./redirectionService";
import RestService, {ErrorDetails, ResponseStatus, RestCall} from "./restService";
import EnvironmentService from "./environmentService";
import {LoginResponseDto, RefreshTokenResponseDto} from "../model/functionModels";

async function verifyLoggedIn(onSuccess: (userDetails : UserDetailsStore) => Promise<any>,
    onFailure: (error: string) => Promise<any>,
    onRedirection: (newUrl: string) => Promise<any>){
    const storeDetails: UserDetailsStore | undefined = LocalStorageService.getUserDetails();
    if (!storeDetails || storeDetails!.refreshTokenExpiry < Date.now() / 1000) {
        // not logged in so we need to redirect you to the login page.
        // your refresh token is expired so we have to redirect you to login screen
        console.log("User Token Expired! Exiting");
        await onRedirection(RedirectionService.redirectToLogin())
    } else if (storeDetails!.accessTokenExpiry < Date.now() / 1000) {
        // your access token is expired. We will try to fetch a new one using the refresh token
        console.log("User Token Expired! Refreshing");
        await attemptTokenRefreshing(onSuccess, onFailure, onRedirection, storeDetails);
    } else {
        // you are logged in,
        await onSuccess(storeDetails);
    }
}

async function attemptTokenRefreshing(onSuccess: (userDetails : UserDetailsStore) => Promise<any>,
                                      onFailure: (error: string) => Promise<any>,
                                      onRedirection: (newUrl: string) => Promise<any>,
                                      userDetails? : UserDetailsStore) {
    let safeUserDetails = userDetails;
    if (!userDetails) {
        safeUserDetails = LocalStorageService.getUserDetails();
    }
    if (!safeUserDetails) {
        // you have no details saved so we should redirect you to the login screen
        await onRedirection(RedirectionService.redirectToLogin());
    } else {
        await RestService.doUnauthenticatedCall({
                url: EnvironmentService.getFunctionUrl() + "/auth/tokens",
                method: "patch",
                body: {
                    refreshToken: safeUserDetails.refreshToken
                },
            } as RestCall,
            async (response : ResponseStatus) => {
                const refreshResponse : RefreshTokenResponseDto = response.body;
                LocalStorageService.updateUserDetails(
                    safeUserDetails!.refreshToken,
                    refreshResponse.token,
                    refreshResponse.user as UserDetails);
                await onSuccess(LocalStorageService.getUserDetails()!);
            },
            async (restCall : RestCall, error : ErrorDetails) => {
                console.log(`Failed refreshing with refresh token!`, error);
                await onFailure(`Failed refreshing with refresh token!`);
            })
    }
}

async function attemptLogin(username: string, password: string,
                            onSuccess: () => Promise<any>,
                            onFailure: (errorDetails: string) => Promise<any>) {
    await RestService.doUnauthenticatedCall({
            url: EnvironmentService.getFunctionUrl() + "/auth/tokens",
            method: "post",
            body: {
                username: username,
                password: password
            },
        } as RestCall,
        async (response : ResponseStatus) => {
            const refreshResponse : LoginResponseDto = response.body;
            LocalStorageService.updateUserDetails(
                refreshResponse.refreshToken,
                refreshResponse.token,
                refreshResponse.user as UserDetails);
            await onSuccess();
        },
        async (restCall : RestCall, error : ErrorDetails) => {
            console.log(`Failed logging in with refresh token!`, error);
            await onFailure(error.error);
        })
}



const AuthenticationService = {
    verifyLoggedIn,
    attemptLogin,
}

export default AuthenticationService;