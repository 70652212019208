import RestService, {ErrorDetails, ResponseStatus, RestCall} from "./restService";
import EnvironmentService from "./environmentService";
import {PostExpenseRequestDto, PostPaymentRequestDto} from "../model/functionModels";

async function createPayment(id: string,
                                 creationRequest: PostPaymentRequestDto,
                                 onSuccess: () => Promise<any>,
                                 onFailure: (a: string) => Promise<any>,
                                 onRedirection: (newUrl: string) => Promise<any>) {
    await RestService.doAuthenticatedCall({
            url: `${EnvironmentService.getFunctionUrl()}/expense-groups/${id}/payments`,
            method: "post",
            body: creationRequest
        } as RestCall,
        async (response: ResponseStatus) => {
            await onSuccess();
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed creating payment!`, error);
            await onFailure(error.error);
        }, onRedirection)
}

async function createExpense(id: string,
                             creationRequest: PostExpenseRequestDto,
                             onSuccess: () => Promise<any>,
                             onFailure: (a: string) => Promise<any>,
                             onRedirection: (newUrl: string) => Promise<any>) {
    await RestService.doAuthenticatedCall({
            url: `${EnvironmentService.getFunctionUrl()}/expense-groups/${id}/expenses`,
            method: "post",
            body: creationRequest
        } as RestCall,
        async (response: ResponseStatus) => {
            await onSuccess();
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed creating expense!`, error);
            await onFailure(error.error);
        }, onRedirection)
}



const TransactionService = {
    createPayment,
    createExpense,
}

export default TransactionService;