import './expenseGroupsPage.css';
import React, {Component} from "react";
import UserDetailsService from "../services/userDetailsService";
import {Navigate} from "react-router-dom";
import RedirectionService from "../services/redirectionService";
import ExpenseGroupService from "../services/expenseGroupService";
import {ExpenseGroupDetailedDto, ExpenseGroupDto, ExpenseGroupStateDto, UserDto} from "../model/functionModels";
import {ToastErrorLevel} from "../../App";
import {withRouter} from "./withRouter";
import ExpenseDetails from "./expenseDetails";
import Switcher from "./switcher";
import UserDisplay from "./userDisplay";
import Pagination from "./pagination";
import AddExpenseGroup from "./addExpenseGroup";
import PureModal from "react-pure-modal";
import Button from "./button";

interface ExpenseGroupsState {
    navigation?: string,
    page: number,
    excludeClosed: boolean,
    expenseGroups?: ExpenseGroupDetailedDto[],
    possibleUsers?: UserDto[],
    newGroupName?: string,
    loggedInUser?: UserDto,
    newGroupMembers?: { name: string, id: string }[],
    showCloseModal: boolean,
    pendingCloseGroup?: ExpenseGroupDto
}

interface ExpenseGroupsProps {
    toastGenerator: (body: string, level: ToastErrorLevel) => any,
    onLogout: any,
}

class ExpenseGroups extends Component<ExpenseGroupsProps, ExpenseGroupsState> {

    constructor(props: ExpenseGroupsProps) {
        super(props, {})
        this.handleMembersUpdate = this.handleMembersUpdate.bind(this);
        this.handleNameUpdate = this.handleNameUpdate.bind(this);
        this.handleMembersRemoval = this.handleMembersRemoval.bind(this);
        this.getUserListForGroup = this.getUserListForGroup.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.handleExcludeClosedChanged = this.handleExcludeClosedChanged.bind(this);
        this.refreshExpenseGroupsFromState = this.refreshExpenseGroupsFromState.bind(this);
        this.handleFailure = this.handleFailure.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
        this.closeExpenseGroup = this.closeExpenseGroup.bind(this);
        this.confirmCloseGroup = this.confirmCloseGroup.bind(this);
        const queryParams = new URLSearchParams(window.location.search)
        let excludeClosedParam = queryParams.get("excludeClosed");
        this.state = {
            page: Number(queryParams.get("page")),
            excludeClosed: excludeClosedParam === undefined || excludeClosedParam === "true",
            showCloseModal: false,
        };
    }

    async componentDidMount() {
        if (!this.state.navigation) {
            UserDetailsService.getOwnUserDetails(async userDto => {
                    this.setState({
                        loggedInUser: userDto,
                        newGroupMembers: [{name: `${userDto.firstName} ${userDto.lastName}`, id: userDto.id}],
                    })
                }, async failure => {
                    this.setState({
                        navigation: RedirectionService.redirectToLogin(),
                    })
                },
                this.handleRedirection)

            this.refreshExpenseGroupsFromState();

            UserDetailsService.getAllUsersList(async users => {
                this.setState({
                    possibleUsers: users,
                })
            }, this.handleFailure, this.handleRedirection)
        }
    }

    async handleRedirection(redirectionUrl: string) {
        if (redirectionUrl.startsWith("/login")) {
            this.props.onLogout();
        }
        this.setState({
            navigation: redirectionUrl
        });
    }

    async handleFailure(error: string) {
        this.props.toastGenerator(error, ToastErrorLevel.ERROR);
    }

    async refreshExpenseGroupsFromState() {
        await ExpenseGroupService.getAllExpenseGroups(this.state.excludeClosed,
            async expenseGroups => {
                this.setState({
                    expenseGroups: expenseGroups ? expenseGroups.expenseGroups : [],
                    pendingCloseGroup: undefined,
                    showCloseModal: false,
                });
            }, this.handleFailure, this.handleRedirection);
    }


    async handleSubmit(event: any) {
        // Prevent page reload
        event.preventDefault();
        await ExpenseGroupService.createExpenseGroup({
                name: this.state.newGroupName!,
                involvedUserIds: this.state.newGroupMembers!.map(i => i.id)
            },
            async () => {
                this.props.toastGenerator("Successfully Created Group", ToastErrorLevel.INFO);
                await this.refreshExpenseGroupsFromState();
            }, this.handleFailure, this.handleRedirection);
    }

    handleMembersUpdate(event: any) {
        this.setState({
            newGroupMembers: event
        })
    }

    handleMembersRemoval(event: any) {
        this.setState({
            newGroupMembers: event
        })
    }

    handleNameUpdate(event: any) {
        this.setState({
            newGroupName: event.target.value
        })
    }

    // TODO does not handle having no groups visible nicely

    async updatePage(page: number) {
        let newUrl = RedirectionService.redirectToHome(page, this.state.excludeClosed);
        // @ts-ignore
        this.props.navigate(newUrl);
        this.setState({
            page: page
        })
    }

    async handleExcludeClosedChanged(event: any) {
        let newUrl = RedirectionService.redirectToHome(0, !this.state.excludeClosed);
        // @ts-ignore
        this.props.navigate(newUrl);
        await ExpenseGroupService.getAllExpenseGroups( !this.state.excludeClosed,
            async expenseGroupPage => {
                this.setState({
                    expenseGroups: expenseGroupPage ? expenseGroupPage.expenseGroups : [],
                    excludeClosed: !this.state.excludeClosed,
                })
            }, this.handleFailure, this.handleRedirection);
    }

    getUserListForGroup(groupID: string, users: UserDto[]) {
        let result = [];
        for (let i = 0; i < users.length; i++) {
            result.push(<div className="ExpenseGroups-UsersList-Content" key={groupID + "-User-" + i}><UserDisplay
                user={users[i]} showName={true} abbreviateName={true}/></div>)
        }
        return <div className="ExpenseGroups-UsersList-Container">{result}</div>;
    }

    closeExpenseGroup(group: ExpenseGroupDto) {
        this.setState({
            pendingCloseGroup: group,
            showCloseModal: true
        })
    }


    renderTableRows() {
        let result: JSX.Element[] = [];
            const expenseGroup = this.state.expenseGroups![this.state.page];
            expenseGroup.id = expenseGroup.id.replace(/\s+/g, "");
            result.push(<div
                className={"ExpenseGroups-tbody-tr-main" + ((Number(this.state.page) === (this.state.expenseGroups!.length - 1)) ? " ExpenseGroups-tbody-tr-main-last" : "")}
                key={"ExpenseGroup-tbody-tr-main-" + expenseGroup.id}>
                {/*<div className="ExpenseGroups-tbody-td-name"><h1>{expenseGroup.name}</h1></div>*/}
                { (expenseGroup.state === ExpenseGroupStateDto.OPEN && expenseGroup.canBeClosed) &&
                <div className="ExpenseGroups-tbody-td-state">
                    <Switcher
                        key={"expense-group-switcher-"+expenseGroup.id+"-"+(expenseGroup.canBeClosed && expenseGroup.state === ExpenseGroupStateDto.OPEN)}
                        enabled={expenseGroup.canBeClosed && expenseGroup.state === ExpenseGroupStateDto.OPEN}
                        value={expenseGroup.state === ExpenseGroupStateDto.OPEN}
                        onText="Open"
                        offText="Closed"
                        onChange={(checked: boolean) => {
                            this.closeExpenseGroup(expenseGroup);
                        }}
                    />
                </div>}
                <div
                    className="ExpenseGroups-tbody-td-people">{this.getUserListForGroup(expenseGroup.id, expenseGroup.involvedUsers)}</div>
            </div>);
            result.push(<div className="ExpenseGroups-tbody-tr-expand"
                             key={"ExpenseGroup-tbody-tr-expand-" + expenseGroup.id}>
                {this.state.loggedInUser &&
                <ExpenseDetails expenseGroup={expenseGroup} onFailure={this.handleFailure}
                                loggedInUser={this.state.loggedInUser!}
                                onRedirection={this.handleRedirection} possibleUsers={this.state.possibleUsers!}
                                refreshPageContent={async () => {
                                    await this.refreshExpenseGroupsFromState();
                                }}/> }
            </div>);
        return result;


    }

    async confirmCloseGroup() {
        await ExpenseGroupService.closeExpenseGroup(this.state.pendingCloseGroup!.id,
            async () => {
                await this.refreshExpenseGroupsFromState();
            },
            this.handleFailure,
            this.handleRedirection)
    }

    renderModal() {
        return <div className={ this.state.showCloseModal ? "ExpenseGroupsPage-PureModal-Backdrop" : ""}> <PureModal
            header="Closing Expense Group"
            footer={
                <div className="ExpenseGroupsPage-PureModal-Footer">
                    <Button value={"Close Expense Group"} onClick={this.confirmCloseGroup} enabled={true} width="10vw" dangerButton={true}/>
                    <Button value={"Leave Open"} onClick={() => {
                        this.setState({
                            pendingCloseGroup: undefined,
                            showCloseModal: false
                        });
                    }} enabled={true} width="10vw"/>
                </div>
            }
            isOpen={this.state.showCloseModal}
            closeButtonPosition=""
            onClose={() => {}}>
            <div className="ExpenseGroupsPage-PureModal-Content">
                <p>You are attempting to close the expense group "{this.state.pendingCloseGroup?.name}".</p>
                <p>Note that this is a non-reversible transition.</p>
                <p>You will need to create a new group if you wish to interact with the same people again.</p>
            </div>
        </PureModal>
        </div>
    }

    render() {
        return <div>
        {this.state.navigation && <Navigate to={this.state.navigation}/>}
        { !this.state.expenseGroups ? <div/>
            :  <div>
                    {this.renderModal()}
                    <div className="ExpenseGroups-Wrapper">
                        <div className="ExpenseGroups-TableView">
                            <div className="ExpenseGroups-TableContent">
                                <div className="ExpenseGroups-Header">
                                    {/*TODO reenable*/}
                                    {/*<div className="ExpenseGroups-ClosedExclusionHeader">*/}
                                    {/*    <Switcher*/}
                                    {/*        enabled={true}*/}
                                    {/*        value={this.state.excludeClosed}*/}
                                    {/*        onText="Exclude Closed"*/}
                                    {/*        offText="Include Closed"*/}
                                    {/*        onChange={this.handleExcludeClosedChanged}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="ExpenseGroups-PaginationHeader">
                                        <Pagination page={this.state.page} pageHeaders={this.state.expenseGroups!.map(e => e.name)}
                                                    showWhenSingle={true}
                                                    onChange={(p: number) => {
                                                        this.updatePage(p);
                                                    }}/>
                                    </div>
                                </div>
                                <div className="ExpenseGroups-table">
                                    {/*<div className="ExpenseGroups-thead">*/}
                                    {/*    <div className="ExpenseGroups-thead-tr-name">Name</div>*/}
                                    {/*    <div className="ExpenseGroups-thead-tr-state">State</div>*/}
                                    {/*    <div className="ExpenseGroups-thead-tr-people">People</div>*/}
                                    {/*    <div className="ExpenseGroups-thead-tr-expand"><span>&nbsp;</span></div>*/}
                                    {/*</div>*/}
                                    {this.renderTableRows()}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="ExpenseGroups-NewGroup">
                            { this.state.loggedInUser &&
                                <AddExpenseGroup possibleUsers={this.state.possibleUsers!} toastGenerator={this.props.toastGenerator} onGroupCreation={this.refreshExpenseGroupsFromState} handleRedirection={this.handleRedirection} loggedInUser={this.state.loggedInUser!} /> }
                        </div>
                    </div>
                </div>}
        </div>
    }

}

export default withRouter(ExpenseGroups);
