import RestService, {ErrorDetails, ResponseStatus, RestCall} from "./restService";
import EnvironmentService from "./environmentService";
import {GetExpenseGroupResponseDto, PostExpenseGroupRequestDto} from "../model/functionModels";

async function getAllExpenseGroups(excludeClosed: boolean,
                                    onSuccess: (a: GetExpenseGroupResponseDto) => Promise<any>,
                                    onFailure: (a: string) => Promise<any>,
                                    onRedirection: (newUrl: string) => Promise<any>) {
    // GetExpenseGroupResponseDto
    await RestService.doAuthenticatedCall({
            url: `${EnvironmentService.getFunctionUrl()}/expense-groups?exclude_closed=${excludeClosed}`,
            method: "get",
        } as RestCall,
        async (response: ResponseStatus) => {
            await onSuccess(response.body);
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed getting expense groups!`, error);
            await onFailure(error.error);
        },
        onRedirection)
}

async function createExpenseGroup(creationDetails: PostExpenseGroupRequestDto,
                                  onSuccess: () => Promise<any>,
                                  onFailure: (a: string) => Promise<any>,
                                  onRedirection: (newUrl: string) => Promise<any>) {
    await RestService.doAuthenticatedCall({
            url: EnvironmentService.getFunctionUrl() + "/expense-groups",
            method: "post",
            body: creationDetails
        } as RestCall,
        async (response: ResponseStatus) => {
            await onSuccess();
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed creating expense group!`, error);
            await onFailure(error.error);
        },
        onRedirection)

}

async function closeExpenseGroup(id: string,
                                 onSuccess: () => Promise<any>,
                                 onFailure: (a: string) => Promise<any>,
                                 onRedirection: (newUrl: string) => Promise<any>) {
    await RestService.doAuthenticatedCall({
            url: EnvironmentService.getFunctionUrl() + "/expense-groups/" + id,
            method: "delete",
        } as RestCall,
        async (response: ResponseStatus) => {
            await onSuccess();
        },
        async (restCall: RestCall, error: ErrorDetails) => {
            console.log(`Failed closing expense group!`, error);
            await onFailure(error.error);
        },
        onRedirection)
}


const ExpenseGroupService = {
    getAllExpenseGroups,
    createExpenseGroup,
    closeExpenseGroup,
}

export default ExpenseGroupService;