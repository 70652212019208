import React, {Component} from "react";

interface SwipablePanelsProps {
    panels: any[]
}

interface SwipablePanelsState {
    currentPage: number
}

class SwipablePanels extends Component<SwipablePanelsProps, SwipablePanelsState> {

    touchStartX: number | undefined | null;
    constructor(props: SwipablePanelsProps) {
        super(props, {})
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.state = {
            currentPage: 0
        };
    }

    handlePageChange(index: number) {
        this.setState({
            currentPage: index
        });
    }


    handleTouchStart (event : any) {
        this.touchStartX = event.touches[0].clientX;
    }

    handleTouchEnd(event : any) {
        const touchEndX : number = event.changedTouches[0].clientX;
        const swipeDistance = touchEndX - this.touchStartX!;
        const threshold = window.innerWidth * 0.25; // Set the threshold to 25% of the screen width


        this.touchStartX = null;
        if (swipeDistance > threshold) {
            // Swiped right
            this.setState({
                currentPage: Math.max(0, this.state.currentPage - 1),
            })
        } else if (swipeDistance < -threshold) {
            // Swiped left
            this.setState({
                currentPage: Math.min(this.props.panels.length-1, this.state.currentPage + 1),
            })
        }
    };

    render() {
        return <div   onTouchStart={this.handleTouchStart}
                      onTouchEnd={this.handleTouchEnd}
                      style={{ touchAction: 'pan-y' }} // Allow vertical scrolling
             >
            <div>
                <div>
                    {this.props.panels[this.state.currentPage]}
                </div>
            </div>
        </div>
    }

}

export default SwipablePanels;
