import './addExpenseGroup.css';
import React, {Component} from "react";
import ExpenseGroupService from "../services/expenseGroupService";
import {ToastErrorLevel} from "../../App";
import {Multiselect} from "multiselect-react-dropdown";
import {UserDto} from "../model/functionModels";
import Button from "./button";

interface AddExpenseGroupState {
    newGroupName?: string,
    newGroupMembers?: { name: string, id: string }[],
}

interface AddExpenseGroupProps {
    possibleUsers: UserDto[],
    loggedInUser: UserDto,
    toastGenerator: (body: string, level: ToastErrorLevel) => any,
    onGroupCreation: any,
    handleRedirection: any
}

class AddExpenseGroup extends Component<AddExpenseGroupProps, AddExpenseGroupState> {

    constructor(props: AddExpenseGroupProps) {
        super(props, {})
        this.handleMembersUpdate = this.handleMembersUpdate.bind(this);
        this.handleNameUpdate = this.handleNameUpdate.bind(this);
        this.handleMembersRemoval = this.handleMembersRemoval.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            newGroupName: '',
            newGroupMembers: [{name: `${this.props.loggedInUser.firstName} ${this.props.loggedInUser.lastName}`, id: this.props.loggedInUser.id}]
        };
    }

    async handleSubmit(event: any) {
        // Prevent page reload
        event.preventDefault();
        await ExpenseGroupService.createExpenseGroup({
                name: this.state.newGroupName!,
                involvedUserIds: this.state.newGroupMembers!.map(i => i.id)
            },
            async () => {
                this.props.toastGenerator("Successfully Created Group", ToastErrorLevel.INFO);
                this.props.onGroupCreation();
            }, async e => this.props.toastGenerator(e, ToastErrorLevel.ERROR),
            this.props.handleRedirection);
    }

    handleMembersUpdate(event: any) {
        this.setState({
            newGroupMembers: event
        })
    }

    handleMembersRemoval(event: any) {
        this.setState({
            newGroupMembers: event
        })
    }

    handleNameUpdate(event: any) {
        this.setState({
            newGroupName: event.target.value
        })
    }


    render() {
        return (
            <div className="AddExpenseGroups-NewGroup">
                {this.props.possibleUsers &&
                <form>
                    <div>
                        <div className="AddExpenseGroup-Name"><input type="text" name="name" required placeholder="Name"
                                    onChange={this.handleNameUpdate} value={this.state.newGroupName}/></div>
                        <div className="AddExpenseGroup-Users">
                            <Multiselect
                                options={this.props.possibleUsers.map(u => {
                                    return {name: `${u.firstName} ${u.lastName}`, id: u.id}
                                })}
                                selectedValues={this.state.newGroupMembers} // Preselected value to persist in dropdown
                                onSelect={this.handleMembersUpdate} // Function will trigger on select event
                                onRemove={this.handleMembersRemoval} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        </div>
                        <Button value={"Create Expense Group"} onClick={this.handleSubmit} enabled={true} />
                    </div>
                </form>
                }
            </div>);
    }

}

export default AddExpenseGroup;
