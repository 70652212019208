const USER_DETAILS_STORE_KEY = "authDetails";

export interface UserDetailsStore {
    refreshToken: string,
    refreshTokenExpiry: number,
    accessToken: string,
    accessTokenExpiry: number,
    loggedInUser: UserDetails,
}

export interface UserDetails {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    active: boolean
}

let cachedUserDetails : UserDetailsStore | undefined;

function getUserDetails(): UserDetailsStore | undefined {
    if (cachedUserDetails) {
        return cachedUserDetails;
    }
    const currentStorageDetails = localStorage.getItem(USER_DETAILS_STORE_KEY);
    if (!currentStorageDetails) {
        return;
    } else {
        cachedUserDetails = JSON.parse(currentStorageDetails);
        return cachedUserDetails;
    }
}

function updateUserDetails(refreshToken: string, accessToken: string, userDetails: UserDetails) {
    cachedUserDetails = {
        refreshToken: refreshToken,
        refreshTokenExpiry: inferExpiry(refreshToken),
        accessToken: accessToken,
        accessTokenExpiry: inferExpiry(accessToken),
        loggedInUser: userDetails
    };
    localStorage.setItem(USER_DETAILS_STORE_KEY, JSON.stringify(cachedUserDetails))
}

function signOut() {
    cachedUserDetails = undefined;
    localStorage.removeItem(USER_DETAILS_STORE_KEY);
}

function inferExpiry(token: string) : number {
    const signedTokenParts : string[] = token.split(".");
    return JSON.parse(atob(signedTokenParts[1])).exp;
}


const LocalStorageService = {
    getUserDetails,
    updateUserDetails,
    signOut
}

export default LocalStorageService;